import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import { deleteIcon, editButton, iconLogoGrey } from "../../../assets/images";
import { FiList, FiPlus } from "react-icons/fi";
import { IoSearchSharp } from "react-icons/io5";
import { BiGridAlt } from "react-icons/bi";
import ServicesCategoryCanvas from "../../../components/Business/OffCanvas/ServicesCategoryCanvas";
import BundleCanvas from "../../../components/Business/OffCanvas/BundleCanvas";
import CategoryBox from "./CategoryBox";
import ServiceCard from "./ServiceCard";
import { httpRequest } from "../../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import PriceTable from './PriceTable';
import { NavLink, useNavigate } from "react-router-dom";
import ServiceInside from "../../../components/Business/OffCanvas/ServiceInside";
import DataGridTable from "./DataGrid";
import { Switch } from "../../../components/Website/Forms";
import ServiceGridTable from "./ServiceGrid";
import BundleMenu from "./BundleMenu";
import BundleGridTable from "./BundleGrid";
import { FaPlus } from "react-icons/fa";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import AddServicesCategory from "../../../components/Business/Modals/AddServicesCategory";
import CategoryDataGrid from "./CategoryDataGrid";
import { getBusinessData, updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import SuggestedCategory from "./SuggestedCategory";
import SuggestedServiceCard from "./SuggestedServiceCard";
import BreadCrumbs from "../../../components/Business/BreadCrumbs/BreadCrumbs";
import Loader from "../../../components/Business/Loader/Loader";

const initialState = { isEdit: false, serviceCategory: null, editService: null }

const AllServices = () => {
  let { businessData, counter } = useSelector((state: any) => state.shareDataReducer);
  const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
  const [showServiceCanvas, setServiceCanvasShow] = useState(false);
  const [filterCat, setFilterCat] = useState<any>('all')
  const navigate = useNavigate()

  const [showServicesCategory, setServicesCategoryShow] = useState(false);
  const handleServicesCategoryClose = () => setServicesCategoryShow(false);
  const handleServicesCategoryShow = () => setServicesCategoryShow(true);

  const [showBundleCanvas, setBundleCanvasShow] = useState(false);
  const handleBundleCanvasClose = () => {
    navigate('/business/services');
    setBundleCanvasShow(false)
    getBundle()

  }
  function handleRefresh() {
    getService();
    getPrices();
    getServiceCatagory()
  }
  const handleServiceCanvasClose = () => {
    handleRefresh()
    navigate('/business/services')
    setEditServiceData(initialState)
    setServiceCanvasShow(false)
  };
  const handleServiceCanvasShow = () => setServiceCanvasShow(true);
  const [serviceList, setServiceList] = useState([]);
  const [editServiceData, setEditServiceData] = useState<any>(initialState);
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [selectedTeam, setSelectedTeam] = useState<any>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [priceList, setPriceList] = useState<any>([]);
  const [teamData, setTeamData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const [typeSwitch, setTypeSwitch] = useState<string>('service');
  const [view, setView] = useState('grid');

  const [suggestedCat, setSuggestedCat] = useState<any>([]);
  const [suggestedService, setSuggestedService] = useState<any>([]);
  const [bundleList, setBundleList] = useState<any>([])
  const [catData, setCatData] = useState([]);
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false)


  const dispatch = useDispatch()

  const getServiceCatagory = async () => {
    setCategoryLoading(true)
    const res = await httpRequest(`getServiceCatagory?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status === true) {
      setCatData(res?.data);
    }
    setCategoryLoading(false)
  };

  const getService = async () => {
    setServiceLoading(true)
    const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status) {
      const services = res?.data?.sort((a: any, b: any) => (b?.isActive && 1) || (a?.isActive && -1))
      setServiceList(services);
    }
    setServiceLoading(false)
  };
  const getPrices = async () => {
    const res = await httpRequest(`getBusinessPrice?businessId=${businessData._id}&accessType=list`, "get", null, "json");
    if (res.status) {
      setPriceList(res?.data);
    }
  };

  const getTeamMemberData = async () => {
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res?.status) {
      let customerServiceTeam: any = res?.data?.filter((item: any) => {
        return item?.Role?.some((roleItem: any) => roleItem?.role?.some((teamTitle: any) => teamTitle?.customerService))
      })

      setTeamData(customerServiceTeam);
    }
  };
  const getBranch = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setBranchData(res.data);
    }
  };
  const getBundle = async () => {
    const res = await httpRequest(`getBundle?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setBundleList(res?.data);
    }
  };
  const getSuggestedServiceCategory = async () => {
    const res = await httpRequest(`getSuggestedServiceCategory`, "get", null, "json");
    if (res.status) {
      setSuggestedCat(res?.data);
    }
  };
  const getSuggestedService = async () => {
    const res = await httpRequest(`getSuggestedBusinessService`, "get", null, "json");
    if (res.status) {
      setSuggestedService(res?.data);
    }
  };

  useEffect(() => {
    if (businessData) {
      getBundle()
      getServiceCatagory()
      getService();
      getPrices();
      getTeamMemberData();
      getBranch();
      if (businessData?.stepCompleted === 7) {
        getSuggestedServiceCategory()
        getSuggestedService()
      }
    }
  }, [businessData]);

  const filterService = serviceList?.filter((service: any) => {
    const teamCheck = !selectedTeam ? true : selectedTeam?.serviceId?.some((ser: any) => ser?._id === service?._id);
    const branchCheck = !selectedBranch ? true : service?.branchId?.some((branch: any) => branch?._id === selectedBranch)
    const catCheck = filterCat === 'all' || service?.serviceCatagoryId === filterCat?._id
    return teamCheck && branchCheck && catCheck
  })
  const filterPrice = priceList?.filter((price: any) => !searchQuery ? true : price?.priceText?.toLocaleLowerCase()?.replaceAll(' ', '')?.includes(searchQuery?.toLocaleLowerCase()?.replaceAll(' ', '')))

  let groupedServicesArray = Object.values(
    filterService?.reduce((acc: any, service: any) => {
      const category = service.ServiceCategory.serviceCategoryName;
      if (!acc[category]) {
        acc[category] = { serviceCategoryName: category, categoryObj: service.ServiceCategory, services: [] };
      }
      acc[category].services.push(service);
      return acc;
    }, {})
  );
  groupedServicesArray = groupedServicesArray?.sort((a: any, b: any) => (b?.services?.every((item: any) => item?.isActive) && 1) || (a?.services?.every((item: any) => item?.isActive) && -1))


  // const groupedSuggestedServicesArray = Object.values(
  //   suggestedService?.reduce((acc: any, service: any) => {
  //     const category = service.SuperServiceCategory.serviceCategoryName;
  //     if (!acc[category]) {
  //       acc[category] = { serviceCategoryName: category, categoryObj: service.SuperServiceCategory, services: [] };
  //     }
  //     acc[category].services.push(service);
  //     return acc;
  //   }, {})
  // );
  const numbersArray = Array.from({ length: groupedServicesArray?.length }, (_, i) => `${i}`);
  // const suggestedNumbersArray = Array.from({ length: groupedSuggestedServicesArray?.length }, (_, i) => `${i}`);

  const canCreateService = subModuleRoles?.['Services']?.Services?.Create;
  const canCreateCat = subModuleRoles?.['Services']?.['Service Category']?.Create;
  const canCreateBundle = subModuleRoles?.['Services']?.Bundles?.Create;
  const canViewServiceCat = subModuleRoles?.['Services']?.['Service Category']?.View

  const canViewService = subModuleRoles?.['Services']?.Services?.View
  const canViewBundle = subModuleRoles?.['Services']?.Bundles?.View

  const isVisible = +businessData?.stepCompleted < 10 && serviceList?.length > 0 && priceList?.length > 0;

  async function goToTeam() {
    const formBusinessData = new FormData();
    formBusinessData.append('stepCompleted', JSON.stringify(8))
    let businessResponse = await httpRequest(
      `updateBusiness/${businessData?._id}`,
      "PATCH",
      formBusinessData,
      "formdata"
    );
    if (businessResponse?.status) {
      dispatch(getBusinessData({ id: `businessId=${businessData?._id}` }));
      navigate('/business/new-teams')
    }
  }

  const handleNavigate = () => {
    if (serviceList?.length > 0) {
      if (businessData?.stepCompleted < 8) {
        goToTeam()
      } else {
        navigate('/business/new-teams')
      }
    }
  }
  useEffect(() => {
    if (businessData?.stepCompleted < 10) {
      dispatch(updateCounter({ ...counter, branch: branchData?.length, service: serviceList?.length }))
    }
  }, [branchData, serviceList])

  useEffect(() => {
    if (!canViewService) {
      setTypeSwitch("category")
    }
  }, [canViewService])

  const filterSuggestedCat = suggestedCat?.filter((cat: any) => cat?.businessCategoryId?.some((bCat: any) => businessData?.businessCatagoryId?.includes(bCat?._id)))
  const isSuggestionActive = +businessData?.stepCompleted < 8;
  const filterSuggestionServiceList = suggestedService?.filter((item: any) => catData.some((cat: any) => cat?.superServiceCategoryId === item?.SuperServiceCategory?._id))

  console.log(catData, 'catData');

  return (
    <>
      <section className={`${st.pageWrapper} ${view === 'grid' ? st.maxWidth_pageWrapper : ""}`}>
        <StepperRow serviceCount={serviceList?.length} key='service' />
        <div className={`${st.newRedesign_main}`}>
          <Row className="align-items-center">
            <Col md={12}>
              <div className={`${st.head_paraBox} ${st.pt50}`}>
                <h2>Service Menu</h2>
                <p>Manage your business's service offerings by selecting from our predefined list or creating your own service categories and individual services. After setting up
                  a service, you can create bundled offers for one or more services in the "Bundle" tab.</p>
              </div>
            </Col>
          </Row>
          <div className={`${st.tabbingBox} mt-0`}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={canViewService ? "servicesMain" : "bundles"}>
              <div className={`${st.tabbing_pillBox}`}>
                <Nav variant="pills">
                  {canViewService && <Nav.Item>
                    <Nav.Link eventKey="servicesMain">
                      Single Services
                    </Nav.Link>
                  </Nav.Item>}
                  {canViewBundle && canViewService && <span className={`${st.linespan}`}></span>}
                  {canViewBundle && <Nav.Item>
                    <Nav.Link eventKey="bundles">
                      Bundles
                    </Nav.Link>
                  </Nav.Item>}
                </Nav>
                <div className={`${st.gridList_view}`}>
                  <button className={`btn grid ${view === 'grid' ? "active" : ""}`} onClick={() => setView('grid')}>
                    <BiGridAlt />
                  </button>
                  <button className={`btn grid ${view === 'list' ? "active" : ""}`} onClick={() => setView('list')}>
                    <FiList />
                  </button>
                </div>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="servicesMain">
                  <div className={`${st.newRedesign_main}`}>
                    {view === 'grid' && <Row>
                      <Col md={4}>
                        {catData?.length > 0 ? (
                          <>
                            {canViewServiceCat && <CategoryBox setFilterCat={setFilterCat}
                              className='mb-2'
                              catData={catData}
                              categoryLoading={categoryLoading}
                              getServiceCatagory={getServiceCatagory}
                              setEditServiceData={setEditServiceData} filterCat={filterCat} handleServiceCanvasShow={handleServiceCanvasShow} />}

                            {isSuggestionActive && (filterSuggestedCat?.length > 0) && <Col xl={11} lg={12}>
                              <div className={`${st.services_leftMain_shadow}`}>
                                <h3>Service Categories Suggestion</h3>
                                <ul className={`${st.serve_listing}`}>
                                  {filterSuggestedCat?.map((cat: any) => {
                                    return (
                                      <SuggestedCategory key={cat?._id} cat={cat} getServiceCatagory={getServiceCatagory} catData={catData} />
                                    )
                                  })}
                                </ul>
                              </div>
                            </Col>}
                          </>
                        ) : (
                          <>
                            {isSuggestionActive && (filterSuggestedCat?.length > 0) && <Col xl={11} lg={12}>
                              <div className={`${st.services_leftMain_shadow} mb-2`}>
                                <h3>Service Categories Suggestion</h3>
                                <ul className={`${st.serve_listing}`}>
                                  {filterSuggestedCat?.map((cat: any) => {
                                    return (
                                      <SuggestedCategory key={cat?._id} cat={cat} getServiceCatagory={getServiceCatagory} catData={catData} />
                                    )
                                  })}
                                </ul>
                              </div>
                            </Col>}

                            {canViewServiceCat && <CategoryBox setFilterCat={setFilterCat}
                              catData={catData}
                              categoryLoading={categoryLoading}
                              getServiceCatagory={getServiceCatagory}
                              setEditServiceData={setEditServiceData} filterCat={filterCat} handleServiceCanvasShow={handleServiceCanvasShow} />}
                          </>
                        )}


                        {/* team creation button box  */}
                        {isVisible && <Col xl={11} lg={12}>
                          <div className="mt-3">
                            <div className={`${st.branch_maintain_bg} `}>
                              <p>
                                Once you've added all your branches and locations, be sure to add your service menu!
                              </p>
                              <button className="btn" onClick={handleNavigate}>
                                Maintain Team Menu
                              </button>
                            </div>
                          </div>
                        </Col>}
                      </Col>
                      <Col md={8} className="mt-2">
                        <Row className="g-3">
                          <Col md={6}>
                            <Row className="g-3">
                              <Col md={5}>
                                <div className={`${st.reFormBox} mb-0`}>
                                  <select className="form-select" value={selectedBranch} onChange={(e: any) => setSelectedBranch(e.target.value)}>
                                    <option value="">Branch</option>
                                    {branchData?.map((branch: any) => {
                                      return (
                                        <option value={branch?._id} key={branch?._id}>{branch?.branchName}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col md={7}>
                                <div className={`${st.reFormBox} mb-0`}>
                                  <select className="form-select" value={selectedTeam?._id} onChange={(e: any) => {
                                    const filterTeam = teamData?.find((team: any) => team?._id === e.target.value)
                                    setSelectedTeam(filterTeam || null)
                                  }}>
                                    <option value="">Team Member</option>
                                    {teamData?.map((team: any) => {
                                      return (
                                        <option value={team?._id} key={team?._id}>{`${team?.firstName} ${team?.lastName || ''}`}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row className="g-3">
                              <Col md={12}>
                                <div className={`position-relative ${st.reFormBox} ${st.searchBoxIcon} mb-0`}>
                                  <input type="search" placeholder="Enter Your Service Type" className="form-control" value={searchQuery} onChange={(e: any) => setSearchQuery(e.target.value)} />
                                  <IoSearchSharp />
                                </div>
                              </Col>

                            </Row>
                          </Col>
                        </Row>
                        {groupedServicesArray?.length > 0 ? (
                          <>
                            {canViewService && !serviceLoading ?
                              (<Row>
                                {groupedServicesArray?.length > 0 ? <Col md={12}>
                                  <div className={`${st.newAccordionList} mt-4 pt-2`}>
                                    <Accordion defaultActiveKey={numbersArray} flush alwaysOpen>
                                      {groupedServicesArray?.map((cat: any, c: number) => {
                                        return (
                                          <Accordion.Item eventKey={`${c}`} key={c}>
                                            <div className={`${st.newAccordionFlexBtn}`}>
                                              <Accordion.Header>
                                                <span className="categoryTypes">{cat?.serviceCategoryName}</span>
                                              </Accordion.Header>
                                              {canCreateService && <button onClick={() => {
                                                setEditServiceData({ ...editServiceData, serviceCategory: cat?.categoryObj })
                                                navigate('/business/services/addService/info')
                                                handleServiceCanvasShow();
                                              }} className={`${st.addServe}`}><FiPlus /> Add New Services</button>}
                                            </div>
                                            <Accordion.Body>
                                              {cat?.services?.map((service: any) => {
                                                const priceData = filterPrice?.filter((price: any) => price?.BusinessService?._id === service?._id)
                                                return (
                                                  <ServiceCard service={service} key={service?._id}
                                                    handleServiceCanvasShow={handleServiceCanvasShow}
                                                    getService={getService}
                                                    getPrices={getPrices}
                                                    priceList={priceData}
                                                    setEditServiceData={setEditServiceData}
                                                  >
                                                    <PriceTable prices={priceData} getBusinessPrice={getPrices} />
                                                  </ServiceCard>
                                                )
                                              })}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        )
                                      })}
                                    </Accordion>
                                  </div>
                                </Col> : filterCat?.serviceCategoryName ? (
                                  <div className={`${st.newAccordionList} mt-4`}>
                                    <div className={`${st.newAccordionFlexBtn} d-flex align-items-center gap-1`}>
                                      <span className="categoryTypes">{filterCat?.serviceCategoryName}</span>
                                      {canCreateService && <button onClick={() => {
                                        setEditServiceData({ ...editServiceData, serviceCategory: filterCat })
                                        navigate('/business/services/addService/info')
                                        handleServiceCanvasShow();
                                      }} className={`${st.addServe}`}><FiPlus /> Add New Services</button>}
                                    </div>
                                    <div className={`${st.edit_publishBranchMain}`}>
                                      <div className={`${st.topBox_withBtn} mb-1`} style={{ padding: '8px' }}>
                                        <h5 style={{ fontSize: '14px' }}>No service in this category</h5>
                                      </div>
                                    </div>
                                  </div>
                                ) : (<></>)}
                              </Row>)
                              : (
                                <div>
                                  <Loader />
                                </div>
                              )}
                            {isSuggestionActive && filterSuggestionServiceList?.length > 0 && <Row>
                              <Col md={12}>
                                <div className={`${st.newAccordionList} mt-4`}>
                                  <div className={`${st.newAccordionFlexBtn} d-flex align-items-center gap-1 mb-2`}>
                                    <span className="categoryTypes">Service Suggestions</span>
                                  </div>
                                  {filterSuggestionServiceList?.map((service: any) => {
                                    return (
                                      <SuggestedServiceCard key={service?._id} service={service} serviceList={filterService} branchData={branchData} getService={getService} catData={catData} setFilterCat={setFilterCat} />
                                    )
                                  })}
                                </div>
                              </Col>
                            </Row>}

                          </>
                        ) : (
                          <>
                            {isSuggestionActive && filterSuggestionServiceList?.length > 0 && <Row>
                              <Col md={12}>
                                <div className={`${st.newAccordionList} mt-4`}>
                                  <div className={`${st.newAccordionFlexBtn} d-flex align-items-center gap-1 mb-2`}>
                                    <span className="categoryTypes">Service Suggestions</span>
                                  </div>
                                  {filterSuggestionServiceList?.map((service: any) => {
                                    return (
                                      <SuggestedServiceCard key={service?._id} service={service} serviceList={filterService} branchData={branchData} getService={getService} catData={catData} setFilterCat={setFilterCat} />
                                    )
                                  })}
                                </div>
                              </Col>
                            </Row>}
                            {canViewService && !serviceLoading ?
                              (<Row>
                                {groupedServicesArray?.length > 0 ? <Col md={12}>
                                  <div className={`${st.newAccordionList} mt-4 pt-2`}>
                                    <Accordion defaultActiveKey={numbersArray} flush alwaysOpen>
                                      {groupedServicesArray?.map((cat: any, c: number) => {
                                        return (
                                          <Accordion.Item eventKey={`${c}`} key={c}>
                                            <div className={`${st.newAccordionFlexBtn}`}>
                                              <Accordion.Header>
                                                <span className="categoryTypes">{cat?.serviceCategoryName}</span>
                                              </Accordion.Header>
                                              {canCreateService && <button onClick={() => {
                                                setEditServiceData({ ...editServiceData, serviceCategory: cat?.categoryObj })
                                                navigate('/business/services/addService/info')
                                                handleServiceCanvasShow();
                                              }} className={`${st.addServe}`}><FiPlus /> Add New Services</button>}
                                            </div>
                                            <Accordion.Body>
                                              {cat?.services?.map((service: any) => {
                                                const priceData = filterPrice?.filter((price: any) => price?.BusinessService?._id === service?._id)
                                                return (
                                                  <ServiceCard service={service} key={service?._id}
                                                    handleServiceCanvasShow={handleServiceCanvasShow}
                                                    getService={getService}
                                                    getPrices={getPrices}
                                                    priceList={priceData}
                                                    setEditServiceData={setEditServiceData}
                                                  >
                                                    <PriceTable prices={priceData} getBusinessPrice={getPrices} />
                                                  </ServiceCard>
                                                )
                                              })}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        )
                                      })}
                                    </Accordion>
                                  </div>
                                </Col> : filterCat?.serviceCategoryName ? (
                                  <div className={`${st.newAccordionList} mt-4`}>
                                    <div className={`${st.newAccordionFlexBtn} d-flex align-items-center gap-1`}>
                                      <span className="categoryTypes">{filterCat?.serviceCategoryName}</span>
                                      {canCreateService && <button onClick={() => {
                                        setEditServiceData({ ...editServiceData, serviceCategory: filterCat })
                                        navigate('/business/services/addService/info')
                                        handleServiceCanvasShow();
                                      }} className={`${st.addServe}`}><FiPlus /> Add New Services</button>}
                                    </div>
                                    <div className={`${st.edit_publishBranchMain}`}>
                                      <div className={`${st.topBox_withBtn} mb-1`} style={{ padding: '8px' }}>
                                        <h5 style={{ fontSize: '14px' }}>No service in this category</h5>
                                      </div>
                                    </div>
                                  </div>
                                ) : (<></>)}
                              </Row>)
                              : (
                                <div>
                                  <Loader />
                                </div>
                              )}
                          </>
                        )}
                      </Col>

                    </Row>}
                    {view === 'list' && <Row>
                      <Col md={12}>
                        <div className={`${st.tablePadding_shadow}`}>
                          <div className={`${st.buttonsAction} mt-0`}>
                            <ul>
                              <li>
                                <ExportBtn />
                              </li>
                              <li>
                                <ImportBtn />
                              </li>
                              {canCreateCat && <li>
                                <NavLink
                                  to="#"
                                  className={`btn ${st.darkBtn}`}
                                  onClick={handleServicesCategoryShow}
                                >
                                  <FaPlus /> Add New Category
                                </NavLink>
                              </li>}
                              {canCreateService && <li onClick={() => {
                                setEditServiceData({ ...editServiceData, serviceCategory: null })
                                handleServiceCanvasShow();
                              }}>
                                <NavLink
                                  to="/business/services/addService/info"
                                  className={`btn ${st.darkBtn}`}
                                >
                                  <FaPlus /> Add New Services
                                </NavLink>
                              </li>}
                            </ul>
                          </div>
                          <div className={`${st.tableBody}`}>
                            <div className={`${st.tableBodyIn} `}>
                              <div className={`${st.type}`}>
                                <div className={`${st.edit_publish_checkBox} `}>
                                  {canViewService && <label className={`${st.radio} d-inline-flex`}>
                                    <input type="radio" value='service' checked={typeSwitch === 'service'} onChange={(e: any) => setTypeSwitch(e.target.value)} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1 mt-1`}>Service Table</span>
                                  </label>}
                                  {canViewService && <label className={`${st.radio} d-inline-flex`}>
                                    <input type="radio" value='price' checked={typeSwitch === 'price'} onChange={(e: any) => setTypeSwitch(e.target.value)} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1 mt-1`}>Price Table</span>
                                  </label>}
                                  {canViewServiceCat && <label className={`${st.radio} d-inline-flex`}>
                                    <input type="radio" value='category' checked={typeSwitch === 'category'} onChange={(e: any) => setTypeSwitch(e.target.value)} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className={`${st.labelName} ms-1 mt-1`}>Service Category Table</span>
                                  </label>}
                                </div>
                                {/* <span>Service Table</span>
                                <Switch
                                  checked={typeSwitch}
                                  onChange={handleTypeChange}
                                  color={"#1AB192"}
                                  backgroundColor="#fff"
                                  key={2}
                                  id={"team"}
                                  className={"mt-2"}
                                />
                                <span>Price Table</span> */}
                              </div>
                              {typeSwitch === 'service' ? (
                                <ServiceGridTable serviceList={serviceList} setEditServiceData={setEditServiceData} getService={getService} getMainService={getPrices} handleServiceCanvasShow={handleServiceCanvasShow} />
                              ) : typeSwitch === 'price' ? (
                                <DataGridTable serviceList={priceList} setEditServiceData={setEditServiceData} getService={getService} getMainService={getPrices} handleServiceCanvasShow={handleServiceCanvasShow} />
                              ) : (
                                <CategoryDataGrid catData={catData} getServiceCatagory={getServiceCatagory} />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="bundles">
                  {view === 'grid' ? (
                    <BundleMenu bundleList={bundleList} getBundle={getBundle} isVisible={isVisible} handleNavigate={handleNavigate} />
                  ) : (
                    <Row>
                      <Col md={12}>
                        <div className={`${st.tablePadding_shadow}`}>
                          <div className={`${st.buttonsAction} mt-0`}>
                            <ul>
                              <li>
                                <ExportBtn />
                              </li>
                              <li>
                                <ImportBtn />
                              </li>
                              {canCreateBundle && <li>
                                <NavLink
                                  to="/business/services/add-bundle/info"
                                  className={`btn ${st.darkBtn}`}
                                  onClick={() => {
                                    setBundleCanvasShow(true)
                                  }}
                                >
                                  <FaPlus /> Add New Bundle
                                </NavLink>
                              </li>}
                            </ul>
                          </div>
                          <div className={`${st.tableBody}`}>
                            <div className={`${st.tableBodyIn} `}>
                              <div className={`${st.type}`}>
                                {/* <span>Service Table</span>
                                <Switch
                                  checked={typeSwitch}
                                  onChange={handleTypeChange}
                                  color={"#1AB192"}
                                  backgroundColor="#fff"
                                  key={2}
                                  id={"team"}
                                  className={"mt-2"}
                                />
                                <span>Price Table</span> */}
                              </div>
                              <BundleGridTable bundleList={bundleList} getBundle={getBundle} />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>

      <ServicesCategoryCanvas show={showServiceCanvas} handleServiceCanvasClose={handleServiceCanvasClose} >
        {showServiceCanvas && <ServiceInside editServiceData={editServiceData} handleServiceCanvasClose={handleServiceCanvasClose} />}
      </ServicesCategoryCanvas>
      {showServicesCategory && <AddServicesCategory
        show={showServicesCategory}
        handleServicesCategoryClose={handleServicesCategoryClose}
        getServiceCatagory={getServiceCatagory} />}
      <BundleCanvas show={showBundleCanvas} handleBundleCanvasClose={handleBundleCanvasClose} />
    </>
  );
};

export default AllServices;
