import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Nav, ProgressBar, Row, Tab, Table } from "react-bootstrap";
import cx from "./index.module.scss";
import { bulb, teamPerson1 } from "../../../assets/images";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import StepperRow from "../../../components/Business/StepperBox/StepperRow";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import RecentNotification from "./RecentNotification";
import RecentChat from "./RecentChat";
import TipsOfTheDay from "./TipsOfDay";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import { getDynamicURIName } from "../../../Utils/Funcs";
import { useDispatch, useSelector } from "react-redux";
import { httpRequest } from "../../../Apis/commonApis";
import { formatDateRange, formatSlashDate } from "../../../constants/Constants";
import { updateCounter } from "../../../redux_toolkit/reducer/shareDataReducer";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../Firebase";

const Dashboard = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate()
  const dynamicName = getDynamicURIName(location.pathname);
  const [activeTab, setActiveTab] = useState('today');
  let { branchId, currentBranchData } = useSelector((state: any) => state.BranchReducer);
  let { businessData, counter } = useSelector((state: any) => state.shareDataReducer);
  const dispatch = useDispatch()

  const [tipList, setTipList] = useState<any>([])
  const [headlineList, setHeadlineList] = useState<any>([])

  const [promotionList, setPromotionList] = useState<any>([])
  const [branchData, setBranchData] = useState<any>([]);
  const [bundleList, setBundleList] = useState<any>([]);
  const [serviceList, setServiceList] = useState([]);
  const [teamList, setTeamList] = useState<any>([]);
  const [notification, setNotification] = useState<any>([]);

  useEffect(() => {
    if (businessData) {
      getBranches();
      getService()
      getBundle();
      getPromotion()
      getTeamMember()
    }
  }, [businessData]);

  async function getTip() {
    const response = await httpRequest('gettipoftheday', 'GET', null, 'json');
    if (response?.status) {
      setTipList(response?.data)
    }
  }
  async function getHeadline() {
    const response = await httpRequest('gethotheadline', 'GET', null, 'json');
    if (response?.status) {
      setHeadlineList(response?.data)
    }
  }
  useEffect(() => {
    getTip()
    getHeadline()
  }, [])

  const getTeamMember = async () => {
    const res: any = await httpRequest(`getAllTeamMembers?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res?.status) {
      setTeamList(res?.data)
    }
  };

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData._id}&type=list`, "get", null, 'json');
    if (res.status === true) {
      setBranchData(res.data);
    }
  }
  const getService = async () => {
    const res = await httpRequest(`getBusinessService?businessId=${businessData._id}&type=list`, "get", null, "json");
    if (res.status) {
      setServiceList(res?.data);
    }
  };
  const getPromotion = async () => {
    if (businessData) {
      let res = await httpRequest(`getPromotion?businessId=${businessData?._id}&type=list`, "get", null, "json");
      if (res.status) {
        setPromotionList(res?.data);
      }
    }
  };

  const getBundle = async () => {
    const res = await httpRequest(`getBundle?businessId=${businessData?._id}&type=list`, "get", null, "json");
    if (res.status) {
      setBundleList(res?.data);
    }
  };
  useEffect(() => {
    if (dynamicName === '*') {
      setActiveTab('today')
      navigate('/business/dashboard/today')
    } else {
      setActiveTab(dynamicName);
    }

  }, [dynamicName])
  useEffect(() => {
    if (businessData?.stepCompleted >= 10) {
      dispatch(updateCounter({ ...counter, branch: branchData?.length, service: serviceList?.length, team: teamList?.length, bundles: bundleList?.length, promotion: promotionList?.length }))
    }
  }, [branchData, serviceList, teamList, bundleList, promotionList, businessData?.stepCompleted, dispatch])

  useEffect(() => {
    if (branchId) {
      const unSub = onSnapshot(
        doc(db, "notification", branchId),
        (doc: any) => {
          if (doc.exists()) {
            const notification = doc.data().notification?.sort((a: any, b: any) => b?.date - a?.date)
            setNotification(notification?.slice(0, 5))
          } else {
            setNotification([])
          }
        }
      );
      return () => {
        unSub();
      };
    }
  }, [branchId]);

  const handleTabSelect = (tab: any) => {
    setActiveTab(tab);
    navigate(`${tab}`, { replace: true });
  };
  const tabItemList = ['today', 'week', 'month'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getWeekAndMonthRangeFromMonday() {
    const today = new Date();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDay = today.getDay();

    // Calculate the difference to the previous Monday
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;

    // Set Monday as the start date of the week
    const monday = new Date(today);
    monday.setDate(today.getDate() + diffToMonday);

    // Set Sunday as the end date of the week
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    // Get the start of the current month (1st of the current month)
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Get the end of the current month (last day of the current month)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return {
      weekRange: {
        startOfWeek: monday,
        endOfWeek: sunday
      },
      monthRange: {
        startOfMonth: startOfMonth,
        endOfMonth: endOfMonth
      }
    };
  }

  const range = getWeekAndMonthRangeFromMonday();

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <Row>
          <Col xl={8}>
            <StepperRow type='dashboard' />
          </Col>
          {headlineList?.length > 0 && <Col xl={4}>
            <div className={`${cx.promoLine_gap}`}>
              {headlineList?.map((headline: any) => {
                return (
                  <div className={`${cx.promoLine_card}`} key={headline?._id}>
                    <h2>{headline?.title || ''}</h2>
                    <p>{headline?.content || ''}</p>
                    <a className="btn" href={headline?.url} target="_blank">View More</a>
                  </div>
                )
              })}
            </div>
          </Col>}
        </Row>

        <Row>
          <Col xl={4} md={6} className="mb-4">
            <RecentNotification notification={notification} key={branchId} />
          </Col>
          <Col xl={4} md={6} className="mb-4">
            <RecentChat key={branchId} />
          </Col>
          {tipList?.length > 0 && <Col xl={4} md={12} className="mb-4">
            <div className={`${cx.notification_card} ${cx.notification_TipCard}`}>
              <img className={`${cx.tipImg}`} src={bulb} alt="tip" />
              <div className={`${cx.notification_header}`}>
                <div className={`${cx.notification_titleCount}`}>
                  <h2>Tip of the day</h2>
                </div>
              </div>
              <ul className={`${cx.notification_item}`}>
                {tipList?.map((item: any) => {
                  return (
                    <TipsOfTheDay item={item} key={item?._id} />
                  )
                })}
              </ul>
            </div>
          </Col>}
        </Row>

        <Row>
          <Col lg={12}>
            <div className={`${st.tabbingBox} mt-0`}>
              <Tab.Container id="left-tabs-example" onSelect={handleTabSelect} activeKey={activeTab}>
                <Nav variant="pills">
                  {tabItemList?.map((tab: string, i: number) => {
                    return (
                      <>
                        <Nav.Item key={tab}>
                          <Nav.Link eventKey={tab}>
                            <div className="d-flex flex-column">
                              <span>{tab} at Glance</span>
                              <span style={{ fontSize: '12px', textAlign: 'center' }}>({
                                i === 2 ?
                                  formatDateRange(range?.monthRange?.startOfMonth, range?.monthRange?.endOfMonth) : i === 1 ?
                                    formatDateRange(range?.weekRange?.startOfWeek, range?.weekRange?.endOfWeek) : formatDateRange(new Date())
                              })</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        {i !== (tabItemList?.length - 1) && <span className={`${st.linespan}`} style={{ height: '36px', marginTop: '15px' }}></span>}
                      </>
                    )
                  })}
                </Nav>
                <Tab.Content>
                  <Routes>
                    <Route path={activeTab} element={<DashboardRoutes dynamicName={dynamicName} range={range} />} />
                  </Routes>
                </Tab.Content>
              </Tab.Container>

            </div>
          </Col>
        </Row>


      </section >
    </>
  );
};

export default Dashboard;
