import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
    iconPassword,
    loginBg,
} from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { httpRequest } from "../../../Apis/commonApis";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";

const ResetPassword = () => {

    const params = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [show, setShow] = useState(true);
    const [show2, setShow2] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState('');

    const handleAlertModalClose = () => setShowErrorModal('');
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const changePassword = async (postdata: any) => {
        if (postdata.newPassword !== postdata.newPassword2) {
            handleAlertModalShow(`Error, Password does not match`);
            return;
        }
        let data = {
            newPassword: postdata.newPassword
        }
        const res = await httpRequest(`changepassword/${params.uid}`, 'PATCH', data, 'withoutToken');
        if (res.status) {
            handleAlertModalShow(`Success, ${res.message}`);
            setTimeout(() => {
                handleAlertModalClose();
                navigate("/business/login", { replace: true });
            }, 3000);
        } else {
            handleAlertModalShow(`Error, ${res.message}`);
        }
    }

    return (
        <>
            <section className={`${cx.loginSection}`}>
                <img src={loginBg} alt="login background" className={`${cx.loginBg}`} />

                <div className={`${cx.modalContent}`}>
                    <div className={`${cx.modalCenter}`}>
                        <div className={`${cx.formBody}`}>
                            <form onSubmit={handleSubmit(changePassword)}>
                                <Row>
                                    <Col md={12}>
                                        <h2 className={`${cx.title}`}>Enter new password</h2>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                                            <label className="form-label">New Password</label>
                                            <div className="position-relative">
                                                {/* <img
                                                    src={iconPassword}
                                                    className={`${st.icon}`}
                                                    alt="password icon"
                                                /> */}
                                                <input
                                                    className="form-control"
                                                    placeholder="Your password"
                                                    type={show ? "password" : "text"}
                                                    {...register("newPassword", {
                                                        required: true,
                                                        pattern: /[0-9a-zA-Z]/,
                                                    })}
                                                />
                                                {show ? (
                                                    <AiFillEye
                                                        className={`${st.eyeIcon}`}
                                                        onClick={() => {
                                                            setShow(false);
                                                        }}
                                                    />
                                                ) : (
                                                    <AiFillEyeInvisible
                                                        className={`${st.eyeIcon}`}
                                                        onClick={() => {
                                                            setShow(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            {errors?.newPassword?.type === "required" && (
                                                <p className={"errorMessage"}>This field is required</p>
                                            )}
                                            {errors?.newPassword?.type === "pattern" && (
                                                <p className={"errorMessage"}>minimum 6 character is necessary .</p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className={`${st.formBox} ${st.iconFormBox}`}>
                                            <label className="form-label">Re-enter Your New Password</label>
                                            <div className="position-relative">
                                                {/* <img
                                                    src={iconPassword}
                                                    className={`${st.icon}`}
                                                    alt="password icon"
                                                /> */}
                                                <input
                                                    className="form-control"
                                                    placeholder="Your password"
                                                    type={show2 ? "password" : "text"}
                                                    {...register("newPassword2", {
                                                        required: true,
                                                        pattern: /[0-9a-zA-Z]/,
                                                    })}
                                                />
                                                {show2 ? (
                                                    <AiFillEye
                                                        className={`${st.eyeIcon}`}
                                                        onClick={() => {
                                                            setShow2(false);
                                                        }}
                                                    />
                                                ) : (
                                                    <AiFillEyeInvisible
                                                        className={`${st.eyeIcon}`}
                                                        onClick={() => {
                                                            setShow2(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            {errors?.newPassword2?.type === "required" && (
                                                <p className={"errorMessage"}>This field is required</p>
                                            )}
                                            {errors?.newPassword2?.type === "pattern" && (
                                                <p className={"errorMessage"}>minimum 6 characters required</p>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className={`${st.formBox}`}>
                                            <div className={`${st.btnsGroup} mt-2`}>
                                                <button
                                                    type="submit"
                                                    className={`btn ${st.btn3}`}
                                                >
                                                    Change Password
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="d-flex justify-content-center"> Back to&nbsp;
                                            <NavLink className={`${cx.alreadyBox} m-0`} to="/business/login">
                                                Log In
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </div>
                <ErrorPopup
                    showErrorModal={showErrorModal}
                    handleErrorModalClose={handleAlertModalClose}
                />
            </section>
        </>
    );
};

export default ResetPassword;
