import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Col, Row } from "react-bootstrap";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    location,
    iconEmail,
    menu,
} from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { AddNewCategoryBtn } from "../../../components/Business";
// import PhoneInput from "react-phone-input-2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import axios from 'axios';
import { useForm } from "react-hook-form";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import { useSelector } from 'react-redux';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import HoverEyeContent from '../../../components/Business/HoverEyeContent/HoverEyeContent';

export default function BasicInfo(props: any) {
    const { businessData,  handleStepsFormData, editBranchData, loading, setErrorTrig, selectedCatIds,branchRef, branchName, setSelectedCatIds } = props;
    let { allBusCategories } = useSelector((state: any) => state.shareDataReducer)
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone3, setPhone3] = useState("");
    const [phoneError, setPhoneError] = useState("");
    // const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
    const [latLong, setLatLong] = useState<{ lat: number, lng: number } | null>(null);
    const [showErrorModal, setShowErrorModal] = useState('');
    
    const handleAlertModalClose = () => setShowErrorModal('');
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm({
        mode: "onTouched",
        defaultValues:{
            country:'Singapore',
            postalCode:'',
            flatNumber:'',
            city:'Singapore',
            address1:'',
            landmark:'',
            email:''

        }
    });
    const submitHandler = async (postdata: any) => {
        if (!phone1) {
            setPhoneError('phone1')
            return
        }
        if (phone1 && !isValidPhoneNumber(phone1)) {
            setPhoneError('phone1')
            return
        }
        
        if (selectedCatIds?.length < 1 || branchName === '') {
            branchRef?.current?.scrollIntoView({ behaviour: "smooth" })
            setErrorTrig(true)
            return;
        }
        let basicinfo: any = {
            businessId: businessData?._id,
            email: postdata?.email,
            phoneNumber1: phone1,
            phoneNumber2: phone2,
            latitude: latLong?.lat,
            longitude: latLong?.lng,
            whatsAppNumber: phone3,
            city: postdata?.city,
            country: postdata?.country,
            postalCode: postdata?.postalCode,
            address1: postdata?.address1,
            address2: postdata?.address2,
            step:'1',
            landmark: postdata?.landmark,
            flatNumber: postdata?.flatNumber,
            businessCatagoryId: JSON.stringify(selectedCatIds?.map((item: any) => item?.value)),
        };
        if (editBranchData?.branchName !== branchName) {
            basicinfo.branchName = branchName;
        }
        handleStepsFormData(basicinfo, 'step1');
    };

    const handlePhone1Change: any = (value: any, selectedCountry: any) => {
        setPhone1(value || "");
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    const handlePhone2Change: any = (value: any, selectedCountry: any) => {
        setPhone2(value);
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    const handlePhone3Change: any = (value: any, selectedCountry: any) => {
        setPhone3(value);
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    function handleCatSelect(id: string) {
        if (phoneError !== '') {
            setPhoneError('');
        }
        let newSelected: any;
        if (Array.isArray(selectedCatIds)) {
            newSelected = [...selectedCatIds];
        } else {
            newSelected = [];
        }
        // let newSelected: any = [...selectedCatIds];
        const selectedIndex = newSelected.indexOf(id);
        if (selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1);
        } else {
            newSelected.push(id);
        }
        setSelectedCatIds(newSelected);
    }

    const filteredCats = allBusCategories?.filter((item: any) => selectedCatIds?.includes(item?._id));

    const prefilledCountry: any = businessData?.countryCode ? businessData?.countryCode : 'SG';

    async function getLatLongFromPostcode(postcode: string): Promise<{ lat: number, lng: number }> {
        try {
            const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${postcode}.json?access_token=pk.eyJ1IjoieWlpdSIsImEiOiJjazJvMmJ3M2QwejYzM21tdWdiZzR6cmUwIn0.XolZlohi-gYoIdMoen7Gyg`);
            const { features } = response.data;

            if (features && features.length > 0) {
                const { center } = features[0];
                return { lat: center[1], lng: center[0] };
            } else {
                throw new Error("No results found");
            }
        } catch (error) {
            console.error("Error fetching geolocation: ", error);
            throw error;
        }
    }
    // const result = getLatLongFromPostcode("303005");
    const handleConvert = async (postalCode: any) => {
        try {
            const result = await getLatLongFromPostcode(postalCode);
            setLatLong(result);
        } catch (error) {
            console.error("Error converting postcode: ", error);
        }
    };

    let postalCode = watch("postalCode");
    useEffect(() => {
        if (postalCode) {
            handleConvert(postalCode)
        }
    }, [postalCode])

    useEffect(() => {
        if (editBranchData) {
            reset();
            let selectedcats = editBranchData?.businessCatagoryId;
            if (typeof (selectedcats?.[0]) === 'object') {
                selectedcats = editBranchData?.businessCatagoryId?.map((item: any) => item?._id)
            }
            // setSelectedCatIds(selectedcats);
            setPhone1(`${editBranchData.phoneNumber1?.includes("+") ? "" : "+"}${editBranchData.phoneNumber1}`);
            editBranchData.phoneNumber2 && setPhone2(`${editBranchData.phoneNumber2?.includes("+") ? "" : "+"}${editBranchData.phoneNumber2}`);
            editBranchData.whatsAppNumber && setPhone3(`${editBranchData.whatsAppNumber?.includes("+") ? "" : "+"}${editBranchData.whatsAppNumber}`);

            setValue('flatNumber', editBranchData?.flatNumber)
            setValue('city', editBranchData?.city)
            setValue('postalCode', editBranchData?.postalCode)
            setValue('address1', editBranchData?.address1)
            setValue('landmark', editBranchData?.landmark)
            setValue('country', editBranchData?.country)
            setValue('email', editBranchData?.email)
        }
    }, [editBranchData]);

    console.log(phone3, phoneError, isValidPhoneNumber(phone3), 'phoneDataaaaaaaa');
    

    return (
        <>
            <div className={`${st.newRedesign_main}`}>
                <Row>
                    <Col md={12}>
                        <button className="saveButton" onClick={handleSubmit(submitHandler)}>
                            {loading ? (
                                <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                ></div>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className={`${st.branchContact_tabBox} `}>
                            <h4>Branch / Location Address</h4>
                            <ul className={`${st.contact_addressInputs}`}>
                                <li>
                                    <label>Country:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Country Name' {...register("country", {
                                            required: true,
                                        })} />
                                        {errors?.country?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>City:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your City'
                                            {...register("city", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.city?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>

                                <li>
                                    <label>Postal Code:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="number" className='form-control' placeholder='Enter Your Postal Code'
                                            {...register("postalCode", {
                                                required: true,
                                                pattern: /[0-9a-zA-Z]{6,}/,
                                            })}
                                        />
                                        {errors?.postalCode?.type === "pattern" && (
                                            <p className={"errorMessage mb-0"}>
                                                minimum six character required .
                                            </p>
                                        )}
                                        {errors?.postalCode?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>Block & Street Name:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Block & Street Name'
                                            {...register("address1", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.address1?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>Floor & Unit No.:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Floor & Unit No.' {...register('flatNumber')} />
                                    </div>
                                </li>
                                <li>
                                    <label>Landmarks:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Landmarks'
                                            {...register("landmark", {
                                                required: false,
                                            })}
                                        />
                                        {errors?.landmark?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `}>
                            <h4>Contact Details</h4>
                            <p>Provide Updated contact details. These contact details information would be public and customer can see these details. Wellnezza would be forwarding these contact details to along with the booking details to customer.</p>
                            <ul className={`${st.contact_addressInputs}`} >
                                <li>
                                    <label>Email:</label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            })}
                                        />
                                        {errors?.email?.type === "pattern" && (
                                            <p className={"errorMessage mb-0"}>Invalid email address</p>
                                        )}
                                        {errors?.email?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>this field is required</p>
                                        )}
                                    </div>

                                </li>
                                <li>
                                    <label>Phone No. 1:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        {/* <label className="form-label">Phone number 1 <HoverEyeContent number={9075} /></label> */}
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry='SG'
                                                international
                                                placeholder="Enter phone number"
                                                value={phone1}
                                                onChange={handlePhone1Change}
                                                error={phone1 ? (isValidPhoneNumber(phone1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                            />

                                        </div>
                                        {phone1 && <p className={"errorMessage mb-0"}>{phone1 && isValidPhoneNumber(phone1) ? '' : 'Invalid phone number'}</p>}
                                        {phoneError.includes('phone1') && !isValidPhoneNumber(phone1) && phone1 && (
                                            <p className="errorMessage mb-0">Invalid phone number</p>
                                        )}
                                        {phoneError.includes('phone1') && !phone1 && (
                                            <p className="errorMessage mb-0">this field is required</p>
                                        )}
                                    </div>
                                    {/* <input type="text" className='form-control' placeholder='Enter Your Postal Code' /> */}
                                </li>
                                <li>
                                    <label>Phone No. 2:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        {/* <label className="form-label">Phone number 2 <HoverEyeContent number={9076} /></label> */}
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry='SG'
                                                // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                                                international
                                                placeholder="Enter phone number"
                                                value={phone2}
                                                onChange={handlePhone2Change}
                                            />
                                        </div>

                                    </div>
                                </li>
                                <li>
                                    <label>Whatsapp No.:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry='SG'
                                                // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                                                international
                                                placeholder="Enter whatsapp number"
                                                value={phone3}
                                                onChange={handlePhone3Change}
                                            />
                                        </div>
                                        
                                        {phoneError.includes('phone3') && (
                                            <p className="errorMessage mb-0">Invalid phone number</p>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div >
            {/* <Row>
                <Col md={12}>
                    <div className="text-end">
                        <HelpButon number={33} type={"help"} className={"btn"} />
                    </div>
                </Col>
            </Row> */}

            <Row>
                {/* <Col md={12}>
                    <div className={`${cx.tab_accordion_main}`}>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Business Categories : Services Provided by this Location <HoverEyeContent number={9073} /></Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <p className={`${cx.accordion_para}`}>This information will be show in top Section of your Business page on Wellnezza .
                                            Tell your compelling story in short brief .Keep it short and up to 900 Words</p>

                                        <Col lg={8} md={6}>
                                            <div className={`${st.formBox}`}>
                                                <div className={`${cx.busines_category_itmes}`} >
                                                    <ul className={`${st.iconSelectList} ${cx.icons} justify-content-between`}>
                                                        <AddNewCategoryBtn setSelectedCatIds={setSelectedCatIds}
                                                            style={{ border: "1px solid gainsboro", height: "61px", borderRadius: "9px" }}
                                                            selectedCatIds={selectedCatIds} handleCatSelect={handleCatSelect} />

                                                        <div style={{ width: '87%' }}>
                                                            <OwlCarousel className="owl-theme " {...options}>
                                                                {filteredCats?.map((item: any, index: number) => {
                                                                    return (
                                                                        <div className={'item me-0'}>
                                                                            <li key={index} className={`${st.active}`}>
                                                                                <div className={`${st.iconImg}`}>
                                                                                    <img src={item.Icon} alt="massage" />
                                                                                </div>
                                                                                <h6>{item.BusinessCategoryName}</h6>
                                                                            </li>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </OwlCarousel>
                                                        </div>


                                                    </ul>
                                                </div>

                                                {phoneError.includes('selectCat') && (
                                                    <p className="errorMessage">Category is required</p>
                                                )}
                                            </div>
                                        </Col>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>

                </Col> */}

                {/* <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                                <label className="form-label">Business Country <HoverEyeContent number={9070} /></label>
                                <div className="position-relative d-flex align-items-center gap-1">
                                    <img src={location} alt="budiness icon" />
                                    <p className="m-0"> {businessData?.country}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={`${st.formBox} ${cx.iconPos}`}>
                                <label className="form-label">Business ID <HoverEyeContent number={9071} /></label>
                                <div className="position-relative d-flex align-items-center gap-1">
                                    <p className="m-0">{userData?.customerAccountNo}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col> */}


                {/* 
                <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Branch Name <HoverEyeContent number={9072} /></label>
                        <div className="position-relative">
                            <img
                                src={menu}
                                className={`${cx.icon}`}
                                alt="budiness icon"
                            />
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Branch Name"
                                defaultValue={editBranchData?.branchName || ''}
                                {...register("branchName", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors?.branchName?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col> */}


                {/* <Col md={12} className="mb-2">
                    <h5 className="mb-1">Contact Details</h5>

                </Col>

                <Col lg={6} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Email <HoverEyeContent number={9074} /></label>
                        <div className="position-relative">
                            <img
                                src={iconEmail}
                                className={cx.icon}
                                alt="email icon"
                            />
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                defaultValue={editBranchData?.email}
                                {...register("email", {
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                })}
                            />
                        </div>
                        {errors?.email?.type === "pattern" && (
                            <p className={"errorMessage"}>Invalid email address</p>
                        )}
                       
                    </div>
                </Col> */}
            </Row>
            {/* <Row>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Phone number 1 <HoverEyeContent number={9075} /></label>
                        <div className={`${st.phoneBox}`}>
                            <PhoneInput
                                defaultCountry='SG'
                                international
                                placeholder="Enter phone number"
                                value={phone1}
                                onChange={handlePhone1Change}
                                error={phone1 ? (isValidPhoneNumber(phone1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />

                        </div>
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Phone number 2 <HoverEyeContent number={9076} /></label>
                        <div className={`${st.phoneBox}`}>
                            <PhoneInput
                                defaultCountry='SG'
                                international
                                placeholder="Enter phone number"
                                value={phone2}
                                onChange={handlePhone2Change}
                            />
                        </div>
                        {phoneError.includes('phone2') && (
                            <p className="errorMessage">Invalid phone number</p>
                        )}
                    </div>
                </Col>

                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">WhatsApp number <HoverEyeContent number={9078} /></label>
                        <div className={`${st.phoneBox}`}>
                            <PhoneInput
                                defaultCountry='SG'
                                international
                                placeholder="Enter whatsapp number"
                                value={phone3}
                                onChange={handlePhone3Change}
                            />
                        </div>
                        {phoneError.includes('phone3') && (
                            <p className="errorMessage">Invalid phone number</p>
                        )}
                    </div>
                </Col>

                <Col md={12} className="mt-3 mb-2">
                    <h5>Address</h5>
                </Col>
            </Row> */}
            {/* <Row>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">City <HoverEyeContent number={9079} /></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            defaultValue={editBranchData?.city}
                            {...register("city", {
                                required: true,
                            })}
                        />
                        {errors?.city?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Postal Code <HoverEyeContent number={9080} /></label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Postal Code"
                            defaultValue={editBranchData?.postalCode}
                            {...register("postalCode", {
                                required: true,
                                pattern: /[0-9a-zA-Z]{6,}/,
                            })}
                        />
                        {errors?.postalCode?.type === "pattern" && (
                            <p className={"errorMessage"}>
                                minimum six character required .
                            </p>
                        )}
                        {errors?.postalCode?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
            </Row> */}

            {/* <Row>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Address 1 <HoverEyeContent number={9081} /></label>
                        <div className="position-relative">
                            <img
                                src={location}
                                alt="location icon"
                                className={`${cx.icon} ${cx.v2} ${cx.v4} `}
                            />
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Address 1"
                                defaultValue={editBranchData?.address1}
                                {...register("address1", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors?.address1?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
                <Col lg={4} md={6}>
                    <div className={`${st.formBox} ${cx.iconPos}`}>
                        <label className="form-label">Address 2 <HoverEyeContent number={9082} /></label>
                        <div className="position-relative">
                            <img
                                src={location}
                                alt="location icon"
                                className={`${cx.icon} ${cx.v2} ${cx.v4} `}
                            />
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Address 2"
                                defaultValue={editBranchData?.address2}
                                {...register("address2", {
                                    required: false,
                                })}
                            />
                        </div>
                        {errors?.address2?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col>
            </Row> */}
            <Row>
                {/* <Col lg={4} md={6}>
                    <div className={`${st.formBox}`}>
                        <label className="form-label">Landmark <HoverEyeContent number={9083} /></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Landmark"
                            defaultValue={editBranchData?.landmark}
                            {...register("landmark", {
                                required: false,
                            })}
                        />
                        {errors?.landmark?.type === "required" && (
                            <p className={"errorMessage"}>This field is required</p>
                        )}
                    </div>
                </Col> */}
                {/* <Col md={12}>
                    <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                            <button
                                className={`btn ${st.btn1}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(-1);
                                }}
                            >
                                Back
                            </button>
                            <button type='submit' className={`btn ${st.btn4}`}>
                                {loading ? (
                                    <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                    ></div>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </Col> */}
            </Row>

            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
        </>
    )
}
