import { Col, Row } from "react-bootstrap";
import { deleteIcon, editButton, iconLogoGrey, openIcon } from "../../../assets/images";
import { Status } from "../../../components/Business";
import st from '../../../assets/stylesheet/style.module.scss';
import { useNavigate } from "react-router-dom";
import { httpRequest } from "../../../Apis/commonApis";
import { DeletePopup } from "../../../components/Business/Modals";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ErrorPopup from "../../../components/Business/Modals/ErrorPopup";
import ConfirmationModal from "../../../components/Business/Modals/ConfirmationModal";

export default function BranchItemList({ branch, getBranches, type = '' }: any) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirmation, setShowConfirmation] = useState<any>(false)
    const handleConfirmationClose = () => {
        setShowConfirmation(false)
    }

    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [isPublished, setIsPublished] = useState<boolean>(false)
    const { subModuleRoles } = useSelector((state: any) => state.BranchReducer);
    const canEditBranch = subModuleRoles?.['Business Set Up']?.['Branch/Location']?.Edit;
    const canDeleteBranch = subModuleRoles?.['Business Set Up']?.['Branch/Location']?.Delete;
    const canDeactivate = subModuleRoles?.['Business Set Up']?.['Branch/Location']?.deactivate;

    const navigate = useNavigate()
    const handleEditClick = () => {
        navigate("/business/branch/edit-branch/info", { state: { branchId: branch?._id } });
    };

    const deleteApi = async () => {
        const res = await httpRequest(
            `deleteBranch?id=${branch?._id}`,
            "DELETE",
            null,
            "json"
        );
        if (res.status) {
            getBranches && getBranches('delete');
            handleClose();
        } else {
        }
    };

    const handlePublish = async (e: any) => {
        const checked = !isPublished
        const formData = new FormData()
        formData.append('isPublish', JSON.stringify(checked));
        if (checked && !branch.isActive) {
            handleAlertModalShow(`Error, you can't publish this branch because it's status is disabled`)
            return
        }
        const res = await httpRequest(`updatebranch/${branch?._id}`, 'PATCH', formData, "formdata");
        if (res.status) {
            getBranches && getBranches();
            handleAlertModalShow(`Success, Branch is ${res?.data?.isPublish ? 'published' : 'unpublished'}`)
        } else {
            handleAlertModalShow(`Error, Branch publishing status is not updated`)
        }
        handleConfirmationClose()
    };

    useEffect(() => {
        setIsPublished(branch?.isPublish)
    }, [branch?.isPublish])
    console.log(branch, 'branchData');

    const addressArr = [`${branch?.address1 || ''}`, `${branch.flatNumber || ''}`, `${branch?.landmark || ''}`, `${branch?.city || ''}`, `${branch?.country || ''}`, `${branch?.postalCode || ''}`]
    return (
        <>
            <ConfirmationModal showConfirmation={showConfirmation} message={`${isPublished ? 'unpublish' : 'publish'} this branch`} handleConfirmationClose={handleConfirmationClose} onConfirm={handlePublish} />
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <DeletePopup
                show={show}
                handleClose={handleClose}
                deleteApi={deleteApi}
            />

            <Row className="align-items-center mb-3" key={branch?._id}>
                <Col md={10}>
                    <div className={`${st.edit_publishBranchMain}`}>
                        <div className={`${st.topBox_withBtn}`}>
                            <h5>{branch?.branchName}</h5>
                            <ul className={`${st.btnList}`}>
                                {canEditBranch && <li title='edit'>
                                    <button onClick={handleEditClick}> <img src={editButton} alt="edit" /></button>
                                </li>}
                                {/* <li>
                                    <button> <img src={openIcon} alt="openIcon" /></button>
                                </li> */}
                                {canDeleteBranch && type !== 'publish' && <li title='delete'>
                                    <button> <img src={deleteIcon} alt="deleteIcon" onClick={handleShow} /></button>
                                </li>}
                                {type !== 'publish' && <li className="ms-2">
                                    {canDeactivate && <li>
                                        <Status status={branch?.isActive}
                                            api='updatebranch' id={branch?._id}
                                            apiMethod='formdata'
                                            getLatest={getBranches} />
                                    </li>}
                                </li>}
                            </ul>
                        </div>
                        <ul className={`${st.bottomBox_details}`}>
                            <li className={`${st.leftWidth}`}>
                                {branch?.email && <div className={`${st.infoSide}`}><span>Email:</span> {branch?.email}</div>}
                                <div className={`${st.infoSide}`}><span>Contact No:</span> {branch?.phoneNumber1 ? `${branch?.phoneNumber1}` : ''} {branch?.phoneNumber2 ? `, ${branch?.phoneNumber2}` : ''}</div>
                            </li>
                            <li>
                                <div className={`${st.infoSide}`}><span>Address:</span>{addressArr.join(', ')}</div>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>

                    <span className={`${st.iconLogo_checkBox} mb-3`}>
                        <span className={`${st.borderBox} `}>
                            <label className={`d-inline-flex ${st.checkbox}`}>
                                <input type="checkbox" checked={isPublished} onChange={() => setShowConfirmation(true)} />
                                <span className={`${st.checkmark}`}></span>
                                <span className={`${st.labelName} ms-1`}> <img src={iconLogoGrey} alt="icon" /></span>
                            </label>
                        </span>
                    </span>
                </Col>
            </Row>
        </>
    )
}